import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import addEditInvestorRecovery from './addEditInvestorRecovery';
import recoveryDetails from './recoveryDetails';
import advanceSearch from './advanceSearch';
import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'investorRecovery',
  components: {
    addEditInvestorRecovery,
    recoveryDetails,
    advanceSearch,
    DatePicker,
    ModelSelect
  },
  watch: {
    currentPage: function() {
      this.getRecoveryList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRecoveryList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      payload: null,
      selectAllCheckBox: false,
      showRecoveryDetailModal: false,
      showValueSetModal: false,
      unsubscribe: null,
      searchParams: null,
      showAddRecoveryModal: false,
      showAdvSearchModal: false,
      loader: false,
      unitDisplay: null,
      invoiceBatchName: null,
      einvoiceFlag: { value: null, text: null },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      investorRecoveryRowData: null,
      recoveryType: {
        value: null,
        text: null
      },
      recoveryMode: {
        value: null,
        text: null
      },
      batchName: null,
      gridData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'recovery_mode'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'lease_prj_name',
          label: 'Lease PRJ Name'
        },
        {
          key: 'fms_prj_name',
          label: 'FMS PRJ Name'
        },
        {
          key: 'batch_date'
        },
        {
          key: 'recovery_rate_uom_vset_meaning',
          label: 'Recovery Rate UOM'
        },
        {
          key: 'recovery_rate'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'period_from',
          label: 'From Date'
        },
        {
          key: 'period_to',
          label: 'To Date'
        },
        {
          key: 'exp_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'lib_ac_ccid_dtl',
          label: 'Liability Account'
        },
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'location_name'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'batch_status_vset_meaning',
          label: 'Status'
        },
        {
          key: 'batch_description'
        },
        {
          key: 'invoice_batch_name'
        },
        {
          key: 'e_invoice_flag'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddRecoveryModal = true;
        }
        if (actionName === 'download' && !this.showAddRecoveryModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseRecovery/getInvestorRecovery',
            'investory-recovery',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getRecoveryList() {
      if (!this.searchParams) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          recovery_mode_vset: this.recoveryMode.value,
          recovery_type_vset: this.recoveryType.value,
          batch_name: this.batchName,
          invoice_batch_name: this.invoiceBatchName,
          e_invoice_flag: this.einvoiceFlag.value
        };
      } else {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        Object.assign(this.payload, this.searchParams);
      }
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/getInvestorRecovery', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.gridData = response.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mainSearch() {
      this.searchParams = null;
      this.getRecoveryList();
    },
    advanceSearch(params) {
      this.searchParams = params;
      this.getRecoveryList();
      this.showHideAdvSearchModal(false);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_MODE_VSET) {
        this.recoveryMode.text = item.value_meaning;
        this.recoveryMode.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INVOICE_FLAG_VSET) {
        this.einvoiceFlag.text = item.value_meaning;
        this.einvoiceFlag.value = item.value_code;
      }
    },
    clearParams() {
      this.recoveryMode.text = null;
      this.recoveryMode.value = null;
      this.recoveryType.text = null;
      this.recoveryType.value = null;
      this.batchName = null;
      this.einvoiceFlag.text = null;
      this.einvoiceFlag.value = null;
      this.invoiceBatchName = null;
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.investorRecoveryRowData = item;
      this.showRecoveryDetailModal = true;
    },
    showHideRecoveryModal(flag) {
      this.showAddRecoveryModal = flag;
    },
    showHideRecoveryDetailsModal(flag) {
      this.showRecoveryDetailModal = flag;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    checkUncheckAll() {
      this.gridData = this.gridData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      this.gridData[index].selectBox = flag;
    },
    clearSearchFilters() {
      this.recoveryMode = {
        value: null,
        text: null
      };
      this.recoveryType = {
        value: null,
        text: null
      };
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RECOVERY_MODE_VSET') {
        this.recoveryMode.text = null;
        this.recoveryMode.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('leaseRecovery/saveAdvSearchFilters', null);
    // this.$store.state.leaseRecovery.leaseRecovery.adv_search = null;
  }
};
