import selectInvoice from './selectInvoice';
import unProcessed from './unProcessed';
import inProcess from './inProcess';
import processed from './processed';
import errorDetails from './errorDetails';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import taxCategory from '../../../../../taxation/taxCategory';
import commonHelper from '../../../../../../utility/common.helper.utility';
import codeCombination from '@/app/components/shared/codeCombination';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'recoveryDetails',
  components: {
    selectInvoice,
    unProcessed,
    inProcess,
    processed,
    errorDetails,
    ModelSelect,
    DatePicker,
    taxCategory,
    codeCombination
  },
  props: ['investorRecoveryRowData'],
  data() {
    return {
      totalElements: 0,
      isSuccess: false,
      showAlert: false,
      editMode: false,
      loader: false,
      responseMsg: [],
      showValueSetModal: false,
      scheduleTypeName: 'Recovery',
      scheduleTypeCode: 'SRECOVERY',
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null,
        code: null
      },
      location: {
        location_id: null,
        location_name: null
      },
      trxType: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      batchDate: null,
      batchName: null,
      description: null,
      recoveryBasis: {
        value: null,
        text: null
      },
      tempMsg: [],
      rateUom: {
        value: null,
        text: null
      },
      requestResponse: false,
      ratePerSqft: null,
      glDate: null,
      invoiceDate: null,
      fromDate: null,
      toDate: null,
      expensesAcc: null,
      expensesAccMeaning: null,
      expensesAccCcid: null,
      liabilityAcc: null,
      liabilityAccCcid: null,
      liabilityAccMeaning: null,
      taxCategory: {
        value: null,
        text: null
      },
      sacCode: null,
      hsnCode: null,
      status: null,
      einvoiceFlag: false,
      invoiceBatchName: null,
      isViewMore: false,
      activeTab: 'invoice',
      recoveryMode: {
        value: null,
        text: null
      },
      recMode: {
        value: null,
        text: null
      },
      tdsFlag: false,
      taxCategoryActual: {
        value: null,
        text: null
      },
      taxSection: {
        value: null,
        text: null
      },
      showCodeCombinationModal: false,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      prjType: 'FMS',
      accountType: null,
      showTaxCategory: false,
      batchId:null,
      editableFlag: null
    };
  },
  validations: {
    glDate: {required},
    batchDate: { required },
    fromDate: { required },
    toDate: { required },
    invoiceDate: { required },
  },
  mounted() {
    this.getUomClass();
    this.recMode.text = this.investorRecoveryRowData.recovery_mode;
    if (this.investorRecoveryRowData) {
      this.batchId= this.investorRecoveryRowData
        .lease_investor_recovery_batch_id;
      this.getInvestorRecoveryDetails(this.batchId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.responseMsg=[];
          this.createInvestorRecoveryBatch();
          if (this.activeTab === 'invoice') {
            this.processInvestorRecoveryInvoices();
          } else if (this.activeTab === 'unprocess') {
            if (this.recoveryMode.value === 'FMS_SUB_UNIT') {
              this.approveInvestorRecoveryInvoices();
              this.approveInvestorRecoveryRequest();
            } else if (this.recoveryMode.value === 'FMS_UNIT') {
              this.approveInvestorRecoveryRequest();
            }
          }
        }
      }
    });
  },
  methods: {
    createInvestorRecoveryBatch() {
      if (this.sacCode || this.hsnCode) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const payload = {
            lease_investor_recovery_batch_details: {
              lease_investor_recovery_batch_id: this.batchId,
              recovery_mode_vset: this.recoveryMode.value,
              le_id: Number(this.legalEntity.value),
              ou_id: Number(this.operatingUnit.value),
              fms_prj_id: Number(this.fmsProject.value),
              trx_type_dtl_id: Number(this.trxType.value),
              schedule_type_vset: this.scheduleType.value,
              recovery_type_vset: this.recoveryType.value,
              batch_date: this.batchDate,
              batch_description: this.description,
              recovery_basis: this.recoveryBasis.value,
              recovery_rate_uom_vset: this.rateUom.value,
              recovery_rate: this.ratePerSqft,
              period_from: this.fromDate,
              period_to: this.toDate,
              exp_ac_ccid: this.expensesAccCcid,
              lib_ac_ccid: this.liabilityAccCcid,
              tax_cat_id: this.taxCategory.value,
              location_id: Number(this.location.location_id),
              sac_code: this.sacCode,
              hsn_code: this.hsnCode,
              batch_status_vset: this.status,
              gl_date: this.glDate,
              invoice_date: this.invoiceDate,
              tds_override_flag: this.tdsFlag,
              tds_tax_cat_hdr_id: this.taxCategoryActual.value,
              tds_tax_section_id: this.taxSection.value,
              invoice_batch_name: this.invoiceBatchName,
              e_invoice_flag: this.einvoiceFlag
            }
          };
          this.loader = true;
          this.$store
            .dispatch('leaseRecovery/createInvestoryRecoveryBatch', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.editMode = false;
                this.showAlert = true;
                this.isSuccess = true;
                // this.responseMsg = response.data.message;
                this.responseMsg.push(
                  `Header Details: ${response.data.message}`
                );
                this.batchId =
                  response.data.data.lease_investor_recovery_batch_id;
                this.batchName = response.data.data.batch_name;
                this.status = response.data.data.batch_status_vset;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg.push(
                  `Header Details: ${response.response.data.message}`
                );
                // this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg.push(
                `Header Details: ${appStrings.autoFailedMsg}`
              );
              // this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      } else {
        alert('Please Enter SAC or HSN Code');
      }
    },
    getInvestorRecoveryDetails(leaseInvestorRecoveryBatchId) {
      this.$store
        .dispatch(
          'leaseRecovery/getInvestorRecoveryDetails',
          leaseInvestorRecoveryBatchId
        )
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.recoveryMode.text = results.recovery_mode;
            this.recoveryMode.value = results.recovery_mode_vset;
            this.legalEntity.text = results.le_name;
            this.legalEntity.value = results.le_id;
            this.operatingUnit.text = results.ou_name;
            this.operatingUnit.value = results.ou_id;
            this.fmsProject.text = results.fms_prj_name;
            this.fmsProject.value = results.fms_prj_id;
            this.fmsProject.code = results.fms_prj_code;
            this.location.location_name = results.location_name;
            this.location.location_id = results.location_id;
            this.trxType.text = results.trx_type;
            this.trxType.value = results.trx_type_dtl_id;
            this.scheduleType.text = results.schedule_type_vset_meaning;
            this.scheduleType.value = results.schedule_type_vset;
            this.recoveryType.text = results.recovery_type_vset_meaning;
            this.recoveryType.value = results.recovery_type_vset;
            this.batchDate = results.batch_date;
            this.batchName = results.batch_name;
            this.description = results.batch_description;
            this.recoveryBasis.text = results.recovery_basis_meaning;
            this.recoveryBasis.value = results.recovery_basis;
            this.rateUom.text = results.recovery_rate_uom_vset_meaning;
            this.rateUom.value = results.recovery_rate_uom_vset;
            this.ratePerSqft = results.recovery_rate;
            this.fromDate = results.period_from;
            this.toDate = results.period_to;
            this.expensesAcc = results.exp_ac_ccid_dtl;
            this.expensesAccMeaning = results.exp_ac_ccid_meaning;
            this.expensesAccCcid = results.exp_ac_ccid;
            this.liabilityAcc = results.lib_ac_ccid_dtl;
            this.liabilityAccMeaning = results.lib_ac_ccid_meaning;
            this.liabilityAccCcid = results.lib_ac_ccid;
            this.taxCategory.text = results.category_name;
            this.taxCategory.value = results.tax_cat_id;
            this.sacCode = results.sac_code;
            this.hsnCode = results.hsn_code;
            this.status = results.batch_status_vset_meaning;
            this.invoiceBatchName = results.invoice_batch_name;
            this.einvoiceFlag = results.e_invoice_flag;
            this.glDate = results.gl_date;
            this.invoiceDate = results.invoice_date;
            this.tdsFlag = results.tds_override_flag;
            this.taxCategoryActual.text = results.tax_category_name;
            this.taxCategoryActual.value = results.tds_tax_cat_hdr_id;
            this.taxSection.text = results.section;
            this.taxSection.value = results.tds_tax_section_id;
            this.editableFlag = results.editable_flag
          }
        });
    },
    processInvestorRecoveryInvoices() {
      const objRefrence = this.$refs.invoice.recoveryList;
      const filterData = objRefrence.filter(data => data.selectBox);
      const recoveryDetails = filterData.map(elem => {
        return {
          lease_investor_recovery_temp_id: elem.lease_investor_recovery_temp_id
            ? elem.lease_investor_recovery_temp_id
            : 0,
          lease_investor_agreement_hdr_id: elem.lease_investor_agreement_hdr_id,
          recovery_mode_vset: this.recoveryMode.value,
          vendor_id: elem.vendor_id,
          vendor_site_id: elem.vendor_site_id,
          entity_id:
            this.recoveryMode.value === 'FMS_UNIT'
              ? elem.fms_unit_id
              : elem.fms_sub_unit_id,
          recovery_basis: elem.recovery_basis,
          schedule_type_vset: elem.schedule_type_vset,
          recovery_type_vset: elem.recovery_type_vset,
          trx_type_dtl_id: elem.trx_type_dtl_id,
          lease_recovery_period: elem.lease_recovery_period
            ? elem.lease_recovery_period
            : 0,
          lease_recovery_period_uom_vset: null,
          lease_recovery_rate_uom_vset: elem.recovery_rate_uom_vset,
          lease_recovery_rate: Number(elem.recovery_rate),
          lease_recovery_amount: Number(elem.amount),
          gl_date: elem.gl_date,
          invoice_date: elem.invoice_date,
          tds_override_flag: elem.tds_override_flag,
          tds_tax_section_id: elem.tds_tax_section_id,
          tds_tax_cat_hdr_id: elem.tds_tax_cat_hdr_id,
          period_from: elem.period_from,
          period_to: elem.period_to,
          recovery_process_status: 'U',
          charge_ac_ccid: elem.exp_ac_ccid,
          liability_ac_ccid: elem.lib_ac_ccid,
          tax_cat_id: elem.tax_cat_id,
          location_id: elem.location_id,
          sac_code: elem.sac_code,
          hsn_code: elem.hsn_code ? elem.hsn_code : null,
          posting_status: elem.posting_status ? elem.posting_status : 'U',
          batch_name: this.$refs.invoice.batchName
        };
      });
      const payload = {
        lease_investor_recovery_batch_id: this.investorRecoveryRowData
          .lease_investor_recovery_batch_id,
        lease_investor_recovery_details: recoveryDetails
          ? recoveryDetails
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/invoiceInvestorRecoveryDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.responseMsg.push(`Process Details: ${response.data.message}`);
            this.tempMsg = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Process Details: ${response.response.data.message}`
            );
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Process Details: ${appStrings.autoFailedMsg}`);
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    approveInvestorRecoveryInvoices() {
      const objRefrence = this.$refs.unprocess.recoveryList;
      const filterData = objRefrence.filter(data => data.selectBox);
      const recoveryDetails = filterData.map(elem => {
        return {
          lease_investor_agreement_recovery_id: elem.lease_investor_agreement_recovery_id
            ? elem.lease_investor_agreement_recovery_id
            : 0,
            lease_investor_recovery_temp_id:elem.lease_investor_recovery_temp_id,
          lease_investor_agreement_hdr_id: elem.lease_investor_agreement_hdr_id,
          vendor_id: elem.vendor_id,
          vendor_site_id: elem.vendor_site_id,
          recovery_basis: elem.recovery_basis_vset,
          schedule_type_vset: elem.schedule_type_vset,
          recovery_type_vset: elem.recovery_type_vset,
          trx_type_dtl_id: elem.trx_type_dtl_id,
          lease_recovery_period: elem.lease_recovery_period,
          lease_recovery_period_uom_vset: elem.lease_recovery_period_uom_vset,
          lease_recovery_rate: Number(elem.recovery_rate),
          lease_recovery_rate_uom_vset: elem.lease_recovery_rate_uom_vset,
          lease_recovery_amount: Number(elem.lease_recovery_amount),
          gl_date: elem.gl_date,
          invoice_date: elem.invoice_date,
          tds_override_flag: elem.tds_override_flag,
          tds_tax_section_id: elem.tds_tax_section_id,
          tds_tax_cat_hdr_id: elem.tds_tax_cat_hdr_id,
          period_from: elem.period_from,
          period_to: elem.period_to,
          recovery_process_status: 'U',
          charge_ac_ccid: elem.charge_ac_ccid,
          liability_ac_ccid: elem.liability_ac_ccid,
          tax_cat_id: elem.tax_cat_id,
          location_id: elem.location_id,
          sac_code: elem.sac_code,
          hsn_code: elem.hsn_code
        };
      });
      const payload = {
        lease_investor_recovery_batch_id: this.investorRecoveryRowData
          .lease_investor_recovery_batch_id,
        approve_details: {
          lease_investor_recovery_batch_approve_details: recoveryDetails
            ? recoveryDetails
            : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/processInvestorRecoveryDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.responseMsg.push(`Unprocess Details: ${response.data.message}`);
            // this.tempMsg = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Unprocess Details: ${response.response.data.message}`
            );
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Unprocess Details: ${appStrings.autoFailedMsg}`);
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    approveInvestorRecoveryRequest() {
      const objRefrence = this.$refs.unprocess.recoveryList;
      const filterData = objRefrence.filter(data => data.selectBox);
      const details = filterData.map(elem => {
        return {
          id: elem.lease_investor_recovery_temp_id
        };
      });
      const payload = {
        type: 'INVESTOR',
        requestBody: { schd_id: details ? details : null }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/processInvestorRecoveryRequest', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.responseMsg.push(`Submit Request: ${response.data.message}`);
            this.requestResponse = true;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg.push(
              `Submit Request: ${response.response.data.message}`
            );
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg.push(`Submit Request: ${appStrings.autoFailedMsg}`);
          // this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedTab(tab) {
      this.activeTab = tab;
    },
    getProjectBasedLocation() {
      const payload = {
        prjType: this.prjType,
        prjId: this.fmsProject.value
      };
      this.$store
        .dispatch('leaseRecovery/getProjectBasedLocation', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.location = results;
          }
        });
    },
    getLeasePrjDetailByFmsPrjId() {
      this.$store
        .dispatch(
          'leaseRecovery/getLeasePrjDetailByFmsPrjId',
          this.fmsProject.value
        )
        .then(response => {
          if (response.status === 200) {
            this.fmsProject.code = response.data.data.lease_prj_code;
          }
        });
    },
    openValueSetModal(vsetCode, areaType) {
      // this.vsetCode = vsetCode;
      // this.showValueSetModal = true;
      // this.setTimeVsetCode = setTimeout(() => {
      //   const vSetData = {
      //     valueSetName: vsetCode,
      //     multiFlag: null
      //   };
      //   this.eventBus.$emit('valueSetCode', vSetData);
      // }, 0);
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      // if (this.vsetCode === 'INV_BATCH_NAME' && this.newInvoiceFlag === true) {
      //   return;
      // }
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LOCATION ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY ||
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_BASIS ||
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_MODE_VSET ||
        this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME
      ) {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
        this.fmsProject.text = null;
        this.fmsProject.value = null;
        this.fmsProject.code = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
        this.fmsProject.text = null;
        this.fmsProject.value = null;
        this.fmsProject.code = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.fmsProject.text = item.value_code;
        this.fmsProject.value = item.value_set_dtl_id;
        this.getProjectBasedLocation();
        this.getLeasePrjDetailByFmsPrjId();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.rateUom.text = item.value_meaning;
        this.rateUom.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_BASIS) {
        this.recoveryBasis.text = item.value_meaning;
        this.recoveryBasis.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_MODE_VSET) {
        this.recoveryMode.text = item.value_meaning;
        this.recoveryMode.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TDS_TAX_CATEGORY_ACTUAL
      ) {
        this.taxCategoryActual.text = item.value_code;
        this.taxCategoryActual.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_SECTION_ACTUAL) {
        this.taxSection.text = item.value_code;
        this.taxSection.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.invoiceBatchName = item.value_code;
      }
    },
    // selectedvalueSet(item) {
    //   if (this.vsetCode === 'RECOVERY_MODE_VSET') {
    //     this.recoveryMode.text = item.value_meaning;
    //     this.recoveryMode.value = item.value_code;
    //   }
    // },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getValidatePeriodDate(glDate) {
      const payload = {
        module_id: 21,
        le_id: this.legalEntity.value,
        entity_id: this.operatingUnit.value,
        gl_date: glDate
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              // this.glDate = format(new Date(), appStrings.DATEFNSFORMAT);
              this.glDate = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    disabledBeforeTodayAndTillEndOfMonth(date) {
      return commonHelper.disabledBeforeTodayAndTillEndOfMonth(
        date,
        this.fromDate
      );
    },
    showHideTaxCategoryModal(flag) {
      this.showTaxCategory = flag;
    },
    selectedTaxCategory(item) {
      this.showTaxCategory = false;
      this.taxCategory.text = item.category_name;
      this.taxCategory.value = item.tax_cat_hdr_id;
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.fmsProject.code) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.fmsProject.code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'expense') {
        this.expensesAccCcid = item.ccid;
        this.expensesAcc = item.segment_code;
        this.expensesAccMeaning = item.segment_meaning;
      } else if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'FMS_PROJECT') {
        this.fmsProject.text = null;
        this.fmsProject.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (vsetCode === 'UOM') {
        this.rateUom.text = null;
        this.rateUom.value = null;
      } else if (vsetCode === 'RECOVERY_BASIS') {
        this.recoveryBasis.text = null;
        this.recoveryBasis.value = null;
      } else if (vsetCode === 'RECOVERY_MODE_VSET') {
        this.recoveryMode.text = null;
        this.recoveryMode.value = null;
      } else if (vsetCode === 'TDS_TAX_CATEGORY_ACTUAL') {
        this.taxCategoryActual.text = null;
        this.taxCategoryActual.value = null;
      } else if (vsetCode === 'TDS_SECTION_ACTUAL') {
        this.taxSection.text = null;
        this.taxSection.value = null;
      } else if (vsetCode === 'INV_BATCH_NAME') {
        this.invoiceBatchName = null;
      }
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    }
    // clearVendor() {
    //   this.invoiceBatchName = null;
    // },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
