import DatePicker from 'vue2-datepicker';
export default {
	name: 'errorDetails',
	components: {
		DatePicker
	},
	data() {
		return {
			recoveryList: [],
			recoveryFields: [
				{
					key: 'selectbox',
					label: 'Select All'
				},
				{
					key: 'Project'
				},
				{
					key: 'tower'
				},
				{
					key: 'floor'
				},
				{
					key: 'property'
				},
				{
					key: 'lease_number'
				},
				{
					key: 'applicant_name'
				},
				{
					key: 'invoice_id'
				},
				{
					key: 'invoice_no'
				},
				{
					key: 'invoice_date'
				},
				{
					key: 'invoice_amount'
				},
				{
					key: 'vendor_name'
				},
				{
					key: 'vendor_site'
				},
				{
					key: 'salesforce_id'
				},
				{
					key:'sharing_ratio'
				},
				{
					key: 'total_area'
				},
				{
					key: 'rented_area'
				},
				{
					key: 'area'
				}
			]
		}
	},
	mounted() { },
	methods: {
	}
}