import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AdvanceSearch',
  components: {
    DatePicker,
    ModelSelect
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      parent_value_set_id: null,
      showValueSetModal: false,
      recoveryMode: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null
      },
      scheduleType: {
        value: 'SRECOVERY',
        text: 'Recovery'
      },
      recoveryType: {
        value: null,
        text: null
      },
      rateUom: {
        value: null,
        text: null
      },
      batchName: null,
      fromDate: null,
      toDate: null,
      ratePerSqft: null,
      depVsetParam: null
    };
  },
  mounted() {
    const advFormData = this.$store.state.leaseRecovery.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      this.getUomClass();
    }
  },
  methods: {
    searchParams() {
      const payload = {
        recovery_mode_vset: this.recoveryMode.value,
        recovery_type_vset: this.recoveryType.value,
        batch_name: this.batchName,
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        fms_prj_id: this.fmsProject.value,
        recovery_rate: this.ratePerSqft,
        recovery_rate_uom_vset: this.rateUom.value,
        period_from: this.fromDate,
        period_to: this.toDate
      };
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('leaseRecovery/saveAdvSearchFilters', this);
      // this.$store.state.leaseRecovery.leaseRecovery.adv_search = this;
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PRJ_VSET) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value
      } else if (
        this.vsetCode === 'RECOVERY_MODE_VSET' ||
        this.vsetCode === 'LEASE_RECOVERY'
      ) {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'RECOVERY_MODE_VSET') {
        this.recoveryMode.text = item.value_meaning;
        this.recoveryMode.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PRJ_VSET) {
        this.fmsProject.text = item.value_code;
        this.fmsProject.code = item.fms_prj_code;
        this.fmsProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === 'UOM') {
        this.rateUom.text = item.value_meaning;
        this.rateUom.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setSearchItems(item) {
      this.recoveryMode = item.recoveryMode;
      this.legalEntity = item.legalEntity;
      this.operatingUnit = item.operatingUnit;
      this.fmsProject = item.fmsProject;
      this.scheduleType = item.scheduleType;
      this.recoveryType = item.recoveryType;
      this.intervalClass = item.intervalClass;
      this.rateUom = item.rateUom;
      this.batchName = item.batchName;
      this.fromDate = item.fromDate;
      this.toDate = item.toDate;
      this.ratePerSqft = item.ratePerSqft;

    },
    clearParams() {
      this.recoveryMode.text = null;
      this.recoveryMode.value = null;
      this.legalEntity.text = null;
      this.legalEntity.value = null;
      this.operatingUnit.text = null;
      this.operatingUnit.value = null;
      this.fmsProject.text = null;
      this.fmsProject.value = null;
      this.recoveryType.text = null;
      this.recoveryType.value = null;
      this.rateUom.text = null;
      this.rateUom.value = null;
      this.ratePerSqft = null;
      this.batchName = null;
      this.fromDate = null;
      this.toDate = null;
      this.$store.dispatch('leaseRecovery/saveAdvSearchFilters', null);
      // this.$store.state.leaseRecovery.leaseRecovery.adv_search = null;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'RECOVERY_MODE_VSET') {
        this.recoveryMode.text = null;
        this.recoveryMode.value = null;
      } else if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'LEASE_PRJ_VSET') {
        this.fmsProject.text = null;
        this.fmsProject.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (this.vsetCode === 'UOM') {
        this.rateUom.text = null;
        this.rateUom.value = null;
      }
    },
  }
};
