import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility.js';
import investorLease from '../../../../leases/investorLease';
import fmsTower from '../../../../../../fms/unitInventory/fmsTower';
import fmsFloor from '../../../../../../fms/unitInventory/fmsFloor';
import fmsUnit from '../../../../../../fms/unitInventory/fmsUnit';
import commonHelper from '@/app/utility/common.helper.utility';
import uploadExcel from '@/app/components/shared/uploadExcel';
export default {
  name: 'unProcessed',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    tempMsg: function() {
      if (this.tempMsg) {
        this.recoveryList = this.tempMsg.page;
        this.totalRows = this.tempMsg.total_elements;
      }
    },
    requestResponse: function() {
      if (this.requestResponse) {
        this.recoveryList.forEach(key => {
          key.selectBox = false;
        });
      }
    },
    currentPage: function() {
      this.getUnProcessInvestorRecoveryDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUnProcessInvestorRecoveryDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    }
  },
  components: {
    DatePicker,
    appStrings,
    investorLease,
    fmsTower,
    fmsFloor,
    fmsUnit,
    uploadExcel
  },
  props: ['investorRecoveryRowData', 'editMode', 'tempMsg', 'requestResponse'],
  data() {
    return {
      componentName: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showSubComponentModal: null,
      showCommonModal: null,
      recoveryMode: {
        value: null,
        text: null
      },
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      tenant: {
        value: null,
        text: null
      },
      investor: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      batchName: null,
      fromDate: null,
      toDate: null,
      component: null,
      selectBox: false,
      batch_id: 0,
      responseMsg: '',
      postingStatus: null,
      selectAllCheckBox: false,
      liability_ac_ccid_dtl_meaning: null,
      charge_ac_ccid_dtl_meaning: null,
      recoveryList: [],
      recoveryFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'vendor',
          label: 'Vendor Name'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'unit_name',
          label: 'Office'
        },
        {
          key: 'lease_recovery_rate_uom_vset_meaning',
          label: 'Recovery Rate UOM'
        },
        {
          key: 'recovery_rate'
        },
        {
          key: 'lease_recovery_amount',
          label: 'Amount'
        },
        {
          key: 'final_amount'
        },
        {
          key: 'area_uom_vset',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'SubUnit'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No.'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'period_from',
          label: 'From Date'
        },
        {
          key: 'period_to',
          label: 'To Date'
        },
        {
          key: 'charge_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'liability_ac_ccid_dtl',
          label: 'Liability Account'
        },
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'tds_override_flag',
          label: 'TDS Override Flag',
          class: 'text-center'
        },
        {
          key: 'tax_category_name',
          label: 'TDS Tax Category Actual'
        },
        {
          key: 'section',
          label: 'TDS Section Actual'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'error_meaning',
          label: 'Error'
        },
        {
          key: 'request_num'
        }
      ],
      totalAmount: 0,
      showUploadExcel: false,
      downloadPayload: null
    };
  },
  mounted() {
    this.recoveryMode.value = this.investorRecoveryRowData.recovery_mode_vset;
    if (this.investorRecoveryRowData) {
      const selectProcessRecoveryById = this.investorRecoveryRowData
        .lease_investor_recovery_batch_id;
      this.getUnProcessInvestorRecoveryDetails(selectProcessRecoveryById);
      this.selectedProject.text = this.investorRecoveryRowData.fms_prj_name;
      this.selectedProject.value = this.investorRecoveryRowData.fms_prj_id;
    }
  },
  methods: {
    getUnProcessInvestorRecoveryDetails(batchId) {
      this.loader = true;
      const payload = {
        searchParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_tower_id: this.towerName.value,
          fms_floor_id: this.floorName.value,
          fms_unit_id: this.unitName.value,
          lease_tenant_agreement_no: this.tenant.text,
          batch_name: this.batchName,
          period_from: this.fromDate,
          period_to: this.toDate
        },
        batchId: batchId
      };
      if (this.recoveryMode.value === 'FMS_UNIT') {
        payload.searchParams.lease_investor_agreement_no = this.investor.text;
      } else {
        payload.searchParams.lease_investor_agreement_hdr_id = this.investor.value;
      }
      this.downloadPayload = payload;
      this.$store
        .dispatch(
          'leaseRecovery/getInvestorRecoveryUnProcessByIdDetails',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.charge_ac_ccid_dtl_meaning =
              response.data.data.charge_ac_ccid_dtl_meaning;
            this.liability_ac_ccid_dtl_meaning =
              response.data.data.liability_ac_ccid_dtl_meaning;
            this.postingStatus = response.data.data.posting_status;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectBoxChecked(flag, index) {
      this.recoveryList[index].selectBox = flag;
      this.calculateAmount();
    },
    calculateAmount() {
      let sum = 0;
      this.recoveryList.forEach(item => {
        if (item.selectBox) {
          sum += item.final_amount;
        }
        this.totalAmount = parseFloat(sum).toFixed(2);
      });
    },
    searchParams() {
      this.getUnProcessInvestorRecoveryDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    },
    clearParams() {
      this.towerName.text = null;
      this.towerName.value = null;
      this.floorName.text = null;
      this.floorName.value = null;
      this.unitName.text = null;
      this.unitName.value = null;
      this.investor.text = null;
      this.investor.value = null;
      this.tenant.text = null;
      this.tenant.value = null;
      this.batchName = null;
      this.fromDate = null;
      this.toDate = null;
      this.getUnProcessInvestorRecoveryDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    },
    openCloseSubComponentModal(flag, componentName) {
      this.showSubComponentModal = flag;
      this.componentName = componentName;
    },
    selectedLeaseInvestor(item) {
      this.investor.text = item.lease_investor_agreement_no;
      this.investor.value = item.lease_investor_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    selectFmsTower(item) {
      this.towerName.text = item.tower_name;
      this.towerName.value = item.fms_tower_id;
      this.showSubComponentModal = false;
    },
    selectFmsFloor(item) {
      this.floorName.text = item.floor_name;
      this.floorName.value = item.fms_floor_id;
      this.showSubComponentModal = false;
    },
    selectedFmsUnit(item) {
      this.unitName.text = item.unit_name;
      this.unitName.value = item.fms_unit_id;
      this.showSubComponentModal = false;
    },
    checkUncheckAll() {
      this.recoveryList = this.recoveryList.map(data => {
        if (data.request_num) {
          if (!/e/i.test(data.posting_status)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        return data;
      });
      this.calculateAmount();
    },
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.posting_status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = { ...this.downloadPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'leaseRecovery/getInvestorRecoveryUnProcessByIdDetails',
        'investory-recovery',
        () => (this.loader = false)
      );
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    }
  }
};
