import DatePicker from 'vue2-datepicker';
import store from '../../../../../../../../store';
import appStrings from '@/app/utility/string.utility.js';
import taxCategory from '../../../../../../taxation/taxCategory';
import investorLease from '../../../../leases/investorLease';
import fmsTower from '../../../../../../fms/unitInventory/fmsTower';
import fmsFloor from '../../../../../../fms/unitInventory/fmsFloor';
import fmsUnit from '../../../../../../fms/unitInventory/fmsUnit';
import commonHelper from '@/app/utility/common.helper.utility';
import codeCombination from '@/app/components/shared/codeCombination';
import uploadExcel from '@/app/components/shared/uploadExcel';
export default {
  name: 'selectInvoice',
  watch: {
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    tempMsg: function() {
      if (this.tempMsg) {
        this.recoveryList = this.tempMsg.page;
        this.totalRows = this.tempMsg.total_elements;
      }
    },
    currentPage: function() {
      this.getInvestorRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvestorRecoveryInvoiceByIdDetails(
        this.investorRecoveryRowData.lease_investor_recovery_batch_id
      );
    }
  },
  components: {
    DatePicker,
    taxCategory,
    investorLease,
    store,
    appStrings,
    fmsTower,
    fmsFloor,
    fmsUnit,
    codeCombination,
    uploadExcel
  },
  props: ['investorRecoveryRowData', 'editMode', 'tempMsg', 'totalElements'],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: [],
      componentName: null,
      validateMsg: null,
      tempmsg: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      totalRows: null,
      currentPage: 1,
      selectedIndex: null,
      showSubComponentModal: null,
      loader: false,
      showCommonModal: null,
      showValueSetModal: false,
      selectedItem: null,
      recoveryMode: {
        value: null,
        text: null
      },
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      tenant: {
        value: null,
        text: null
      },
      investor: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      batchName: null,
      fromDate: null,
      toDate: null,
      component: null,
      selectBox: false,
      selectAllCheckBox: false,
      segmentIndex: 0,
      taxIndex: 0,
      transactionTypeModal: false,
      // showConcatSegmentModal: false,
      showTaxCategory: false,
      vsetCode: null,
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      recoveryList: [
        {
          selectAllCheckBox: false,
          selectBox: false,
          le_id: 0,
          le_name: null,

          fms_prj_id: 0,
          fms_prj_name: null,

          recovery_basis: null,
          recovery_basis_meaning: null,

          location_id: 0,
          location_name: null,

          trx_type_dtl_id: 0,
          trx_type: null,

          schedule_type_vset: null,
          schedule_type_vset_meaning: null,

          recovery_type_vset: null,
          recovery_type_vset_meaning: null,

          batch_date: null,
          batch_name: null,
          batch_description: null,
          recovery_rate: null,

          recovery_rate_uom_vset: null,
          recovery_rate_uom_vset_meaning: null,

          period_from: null,
          period_to: null,

          exp_ac_ccid: 0,
          exp_ac_ccid_dtl: null,
          exp_ac_ccid_meaning: null,

          lib_ac_ccid: 0,
          lib_ac_ccid_dtl: null,
          lib_ac_ccid_meaning: null,

          tax_cat_id: 0,
          category_name: null,

          sac_code: null,
          hsn_code: null,

          batch_status_vset: null,
          batch_status_vset_meaning: null,

          amount: null,

          tds_override_flag: false,
          tds_tax_section_id: null,
          section: null,
          tds_tax_cat_hdr_id: null,
          tax_category_name: null
        }
      ],
      recoveryFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'unit_name',
          label: 'Office'
        },
        {
          key: 'recovery_rate_uom_meaning',
          label: 'Recovery Rate UOM'
        },
        {
          key: 'recovery_rate'
        },
        {
          key: 'amount'
        },
        {
          key: 'final_amount'
        },
        {
          key: 'area_uom_vset_meaning',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'fms_floor_name',
          label: 'Floor'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'SubUnit'
        },
        {
          key: 'sharing_ration',
          label: 'Sharing Ratio'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No.'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'recovery_basis_meaning',
          label: 'Recovery Basis'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'period_from',
          label: 'From Date'
        },
        {
          key: 'period_to',
          label: 'To Date'
        },
        {
          key: 'exp_ac_ccid_dtl',
          label: 'Charge Account'
        },
        {
          key: 'lib_ac_ccid_dtl',
          label: 'Liability Account'
        },
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'tds_override_flag',
          label: 'TDS Override Flag',
          class: 'text-center'
        },
        {
          key: 'tax_category_name',
          label: 'TDS Tax Category Actual'
        },
        {
          key: 'section',
          label: 'TDS Section Actual'
        },
        {
          key: 'sac_code',
          label: 'SAC Code'
        },
        {
          key: 'hsn_code',
          label: 'HSN Code'
        }
      ],
      billingPeriodDate: null,
      totalAmount: 0,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      downloadPayload: null,
      showUploadExcel: false,
    };
  },
  mounted() {
    this.recoveryMode.value = this.investorRecoveryRowData.recovery_mode_vset;
    this.getTxnTypeList();
    if (this.investorRecoveryRowData) {
      this.selectedProject.text = this.investorRecoveryRowData.fms_prj_name;
      this.selectedProject.value = this.investorRecoveryRowData.fms_prj_id;
    }
  },
  methods: {
    fillDataFromRecordDetails() {
      this.recoveryList = this.recoveryList.map(elem => {
        elem.le_id = this.investorRecoveryRowData.le_id;
        elem.le_name = this.investorRecoveryRowData.le_name;

        elem.fms_prj_id = this.investorRecoveryRowData.fms_prj_id;
        elem.fms_prj_name = this.investorRecoveryRowData.fms_prj_name;

        elem.recovery_basis_meaning = this.investorRecoveryRowData.recovery_basis_meaning;
        elem.recovery_basis = this.investorRecoveryRowData.recovery_basis;

        elem.location_name = this.investorRecoveryRowData.location_name;
        elem.location_id = this.investorRecoveryRowData.location_id;

        elem.trx_type = this.investorRecoveryRowData.trx_type;
        elem.trx_type_dtl_id = this.investorRecoveryRowData.trx_type_dtl_id;

        elem.schedule_type_vset = this.investorRecoveryRowData.schedule_type_vset;
        elem.schedule_type_vset_meaning = this.investorRecoveryRowData.schedule_type_vset_meaning;

        elem.recovery_type_vset = this.investorRecoveryRowData.recovery_type_vset;
        elem.recovery_type_vset_meaning = this.investorRecoveryRowData.recovery_type_vset_meaning;

        elem.batch_date = this.investorRecoveryRowData.batch_date;
        elem.batch_name = this.investorRecoveryRowData.batch_name;
        elem.batch_description = this.investorRecoveryRowData.batch_description;
        elem.recovery_rate = this.investorRecoveryRowData.recovery_rate;

        elem.recovery_rate_uom_vset = this.investorRecoveryRowData.recovery_rate_uom_vset;
        elem.recovery_rate_uom_vset_meaning = this.investorRecoveryRowData.recovery_rate_uom_vset_meaning;

        elem.period_from = this.investorRecoveryRowData.period_from;
        elem.period_to = this.investorRecoveryRowData.period_to;

        elem.exp_ac_ccid = this.investorRecoveryRowData.exp_ac_ccid;
        elem.exp_ac_ccid_dtl = this.investorRecoveryRowData.exp_ac_ccid_dtl;
        elem.exp_ac_ccid_meaning = this.investorRecoveryRowData.exp_ac_ccid_meaning;

        elem.lib_ac_ccid = this.investorRecoveryRowData.lib_ac_ccid;
        elem.lib_ac_ccid_dtl = this.investorRecoveryRowData.lib_ac_ccid_dtl;
        elem.lib_ac_ccid_meaning = this.investorRecoveryRowData.lib_ac_ccid_meaning;

        elem.tax_cat_id = this.investorRecoveryRowData.tax_cat_id;
        elem.category_name = this.investorRecoveryRowData.category_name;

        elem.sac_code = this.investorRecoveryRowData.sac_code;
        elem.hsn_code = this.investorRecoveryRowData.hsn_code;

        elem.batch_status_vset = this.investorRecoveryRowData.batch_status_vset;
        elem.batch_status_vset_meaning = this.investorRecoveryRowData.batch_status_vset_meaning;
        return elem;
      });
    },
    getInvestorRecoveryInvoiceByIdDetails(batchId) {
      const payload = {
        searchParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_tower_id: this.towerName.value,
          fms_floor_id: this.floorName.value,
          fms_unit_id: this.unitName.value,
          lease_tenant_agreement_no: this.tenant.text,
          period_from: this.fromDate,
          period_to: this.toDate
        },
        batchId: batchId
      };
      if (this.recoveryMode.value === 'FMS_UNIT') {
        payload.searchParams.lease_investor_agreement_no = this.investor.text;
      } else {
        payload.searchParams.lease_investor_agreement_hdr_id = this.investor.value;
      }
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch(
          'leaseRecovery/getInvestorRecoveryInvoiceByIdDetails',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = { ...this.downloadPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'leaseRecovery/getInvestorRecoveryInvoiceByIdDetails',
        'investory-recovery',
        () => (this.loader = false)
      );
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.transactionList = typeList;
        }
      });
    },
    searchParams() {
      if (
        this.towerName.value ||
        this.floorName.value ||
        this.unitName.value ||
        this.investor.value ||
        this.investor.text ||
        this.tenant.text ||
        this.batchName ||
        this.fromDate ||
        this.toDate
      ) {
        this.getInvestorRecoveryInvoiceByIdDetails(
          this.investorRecoveryRowData.lease_investor_recovery_batch_id
        );
      } else {
        alert('Please select alteast one filter');
      }
    },
    clearParams() {
      this.towerName.text = null;
      this.towerName.value = null;
      this.floorName.text = null;
      this.floorName.value = null;
      this.unitName.text = null;
      this.unitName.value = null;
      this.investor.text = null;
      this.investor.value = null;
      this.tenant.text = null;
      this.tenant.value = null;
      this.batchName = null;
      this.fromDate = null;
      this.toDate = null;
    },
    sendName(batchName) {
      this.$emit('selectedBatchName', batchName);
    },
    openCloseSubComponentModal(flag, componentName) {
      this.showSubComponentModal = flag;
      this.componentName = componentName;
    },
    selectedLeaseInvestor(item) {
      this.investor.text = item.lease_investor_agreement_no;
      this.investor.value = item.lease_investor_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    selectFmsTower(item) {
      this.towerName.text = item.tower_name;
      this.towerName.value = item.fms_tower_id;
      this.showSubComponentModal = false;
    },
    selectFmsFloor(item) {
      this.floorName.text = item.floor_name;
      this.floorName.value = item.fms_floor_id;
      this.showSubComponentModal = false;
    },
    selectedFmsUnit(item) {
      this.unitName.text = item.unit_name;
      this.unitName.value = item.fms_unit_id;
      this.showSubComponentModal = false;
    },
    selectedTaxCategory(item) {
      this.recoveryList[this.taxIndex].category_name = item.category_name;
      this.recoveryList[this.taxIndex].tax_cat_hdr_id = item.tax_cat_hdr_id;
      this.showTaxCategory = false;
    },
    showHideTaxCategoryModal(flag, index) {
      this.showTaxCategory = flag;
      this.taxIndex = index;
    },
    showHideCodeCombinationModal(flag, name, index) {
      this.segmentIndex = index;
      this.accountType = name;
      if (flag) {
        if (!this.recoveryList[this.segmentIndex].le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.recoveryList[this.segmentIndex].fms_prj_id) {
          return alert(appStrings.DEPENDENTVSETMSG.FMSPRJMSG);
        }
        this.combinationDetails.le_id = this.recoveryList[
          this.segmentIndex
        ].le_id;
        this.combinationDetails.project_code = this.recoveryList[
          this.segmentIndex
        ].fms_prj_id;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'expense') {
        this.recoveryList[this.segmentIndex].exp_ac_ccid = item.ccid;
        this.recoveryList[this.segmentIndex].exp_ac_ccid_dtl =
          item.segment_code;
        this.recoveryList[this.segmentIndex].exp_ac_ccid_meaning =
          item.segment_meaning;
      } else if (this.accountType === 'liability'){
        this.recoveryList[this.segmentIndex].lib_ac_ccid = item.ccid;
        this.recoveryList[this.segmentIndex].lib_ac_ccid_dtl =
          item.segment_code;
        this.recoveryList[this.segmentIndex].lib_ac_ccid_meaning =
          item.segment_meaning;
      }
      this.showCodeCombinationModal = false;
    },
    selectBoxChecked(flag, index, item) {
      this.selectedItem = item;
      this.recoveryList[index].selectBox = flag;
      this.calculateAmount();
    },
    calculateAmount() {
      let sum = 0;
      // const filterData = this.recoveryList.filter(key => key.selectBox);
      this.recoveryList.forEach(item => {
        if (item.selectBox) {
          sum += item.final_amount;
        }
        this.totalAmount = parseFloat(sum).toFixed(2);
      });
    },
    checkUncheckAll() {
      this.recoveryList = this.recoveryList.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
      this.calculateAmount();
    },
    getCalculatedLeaseRecoveryAmount(index) {
      const payload = {
        period_from: this.recoveryList[index].period_from,
        period_to: this.recoveryList[index].period_to,
        recovery_rate: this.recoveryList[index].recovery_rate,
        area: this.recoveryList[index].area,
        recovery_basis: this.recoveryList[index].recovery_basis
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/getCalculatedLeaseRecoveryAmount', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.recoveryList[index].amount = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValidatePeriodDate(item) {
      const payload = {
        module_id: store.state.shared.moduleId,
        le_id: item.le_id,
        entity_id: item.ou_id,
        gl_date: item.gl_date
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              // this.glDate = format(new Date(), appStrings.DATEFNSFORMAT);
              this.glDate = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TDS_TAX_CATEGORY_ACTUAL) {
        this.recoveryList[this.selectedIndex].tax_category_name =
          item.value_code;
        this.recoveryList[this.selectedIndex].tds_tax_cat_hdr_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_SECTION_ACTUAL) {
        this.recoveryList[this.selectedIndex].section = item.value_code;
        this.recoveryList[this.selectedIndex].tds_tax_section_id =
          item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    disabledDates(date) {
      const restrictedDate = new Date(this.billingPeriodDate);
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    },
    setReferenceDate(row) {
      this.billingPeriodDate = row.period_from;
    }
  }
};
