import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility.js';
import store from '../../../../../../../store';
import taxCategory from '../../../../../taxation/taxCategory';
import commonHelper from '../../../../../../utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'addEditInvestorRecovery',
  components: {
    ModelSelect,
    DatePicker,
    taxCategory,
    codeCombination
    
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      validateMsg: null,
      showValueSetModal: false,
      showTaxCategory: false,
      invoiceBatchName: null,
      newInvoiceFlag: false,
      parent_value_set_id: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null,
        code: null
      },
      trxType: {
        value: null,
        text: null
      },
      trxTypeList: [],
      recoveryMode: {
        value: null,
        text: null
      },
      scheduleType: {
        value: 'SRECOVERY',
        text: 'Recovery'
      },
      recoveryType: {
        value: null,
        text: null
      },
      recoveryBasis: {
        value: null,
        text: null
      },
      batchName: null,
      batchId: 0,
      prjType: 'FMS',
      batchDate: format(new Date(), appStrings.DATEFNSFORMAT),
      description: null,
      area: null,
      rateUom: {
        value: null,
        text: null
      },
      ratePerSqft: null,
      expensesAcc: null,
      expensesAccMeaning: null,
      expensesAccCcid: null,
      liabilityAcc: null,
      liabilityAccCcid: null,
      liabilityAccMeaning: null,
      glDate: null,
      invoiceDate: null,
      fromDate: null,
      toDate: null,
      tdsFlag: false,
      einvoiceFlag: false,
      taxCategoryActual: {
        value: null,
        text: null
      },
      taxSection: {
        value: null,
        text: null
      },
      taxCategory: {
        value: null,
        text: null
      },
      location: {
        location_id: null,
        location_name: null
      },
      sacCode: null,
      hsnCode: null,
      status: 'NEW',
      createdBy: this.getUserName(),
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      lastUpdateBy: null,
      lastUpdateDate: null,
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      isBusy: true,
      segmentMeaning: '',
      filterSegmentCode: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  mounted() {
    if (this.batchId === 0) {
      this.editMode = true;
    }
    this.getTxnTypeList();
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.createInvestorRecoveryBatch();
        }
      }
    });
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    operatingUnit: {
      text: { required }
    },
    fmsProject: {
      text: { required }
    },
    location: {
      location_name: { required }
    },
    trxType: {
      text: {
        required
      }
    },
    recoveryType: {
      text: { required }
    },
    batchDate: { required },
    // glDate: {required},
    invoiceDate: {required},
    fromDate: { required },
    toDate: { required },
    recoveryMode: { text: {required} },
    invoiceBatchName: {required},
    liabilityAcc: {required},
    expensesAcc: {required},
    rateUom: {
      text: {required}
    },
    recoveryBasis: {
      text:{required}
    }
  },
  methods: {
    createInvestorRecoveryBatch() {
      if (this.sacCode || this.hsnCode) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          lease_investor_recovery_batch_details: {
            lease_investor_recovery_batch_id: this.batchId,
            recovery_mode_vset: this.recoveryMode.value,
            le_id: Number(this.legalEntity.value),
            ou_id: Number(this.operatingUnit.value),
            fms_prj_id: Number(this.fmsProject.value),
            trx_type_dtl_id: Number(this.trxType.value),
            schedule_type_vset: this.scheduleType.value,
            recovery_type_vset: this.recoveryType.value,
            batch_date: this.batchDate,
            batch_description: this.description,
            recovery_basis: this.recoveryBasis.value,
            recovery_rate_uom_vset: this.rateUom.value,
            recovery_rate: this.ratePerSqft,
            period_from: this.fromDate,
            period_to: this.toDate,
            exp_ac_ccid: this.expensesAccCcid,
            lib_ac_ccid: this.liabilityAccCcid,
            tax_cat_id: this.taxCategory.value,
            location_id: Number(this.location.location_id),
            sac_code: this.sacCode,
            hsn_code: this.hsnCode,
            batch_status_vset: this.status,
            gl_date: this.glDate,
            invoice_date: this.invoiceDate,
            tds_override_flag: this.tdsFlag,
            tds_tax_cat_hdr_id: this.taxCategoryActual.value,
            tds_tax_section_id: this.taxSection.value,
            invoice_batch_name: this.invoiceBatchName,
            e_invoice_flag: this.einvoiceFlag
          }
        };
        this.loader = true;
        this.$store
          .dispatch('leaseRecovery/createInvestoryRecoveryBatch', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.batchId =
                response.data.data.lease_investor_recovery_batch_id;
              this.batchName = response.data.data.batch_name;
              this.status = response.data.data.batch_status_vset;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    } else {
      alert('Please Enter SAC or HSN Code');
    }
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    getProjectBasedLocation() {
      const payload = {
        prjType: this.prjType,
        prjId: this.fmsProject.value
      };
      this.$store
        .dispatch('leaseRecovery/getProjectBasedLocation', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            this.location = results;
          }
        });
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.trxTypeList = typeList;
        }
      });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.fmsProject.text = item.value_code;
        this.fmsProject.value = item.value_set_dtl_id;
        this.getProjectBasedLocation();
        this.getLeasePrjDetailByFmsPrjId();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.rateUom.text = item.value_meaning;
        this.rateUom.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_BASIS) {
        this.recoveryBasis.text = item.value_meaning;
        this.recoveryBasis.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_MODE_VSET) {
        this.recoveryMode.text = item.value_meaning;
        this.recoveryMode.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TDS_TAX_CATEGORY_ACTUAL
      ) {
        this.taxCategoryActual.text = item.value_code;
        this.taxCategoryActual.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_SECTION_ACTUAL) {
        this.taxSection.text = item.value_code;
        this.taxSection.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.invoiceBatchName = item.value_code;
      }
    },
    clearVendor() {
      this.invoiceBatchName = null;
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      if (this.vsetCode === 'INV_BATCH_NAME' && this.newInvoiceFlag === true) {
        return;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LOCATION ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY ||
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_BASIS ||
        this.vsetCode === appStrings.VALUESETTYPE.RECOVERY_MODE_VSET ||
        this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME
      ) {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideTaxCategoryModal(flag) {
      this.showTaxCategory = flag;
    },
    selectedTaxCategory(item) {
      this.showTaxCategory = false;
      this.taxCategory.text = item.category_name;
      this.taxCategory.value = item.tax_cat_hdr_id;
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.fmsProject.code) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.fmsProject.code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'expense') {
        this.expensesAccCcid = item.ccid;
        this.expensesAcc = item.segment_code;
        this.expensesAccMeaning = item.segment_meaning;
      } else if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    
    disabledBeforeTodayAndTillEndOfMonth(date) {
      return commonHelper.disabledBeforeTodayAndTillEndOfMonth(
        date,
        this.fromDate
      );
    },
   
    getLeasePrjDetailByFmsPrjId() {
      this.$store
        .dispatch(
          'leaseRecovery/getLeasePrjDetailByFmsPrjId',
          this.fmsProject.value
        )
        .then(response => {
          if (response.status === 200) {
            this.fmsProject.code = response.data.data.lease_prj_code;
          }
        });
    },
    getValidatePeriodDate(glDate) {
      const payload = {
        module_id: 21,
        le_id: this.legalEntity.value,
        entity_id: this.operatingUnit.value,
        gl_date: glDate
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              // this.glDate = format(new Date(), appStrings.DATEFNSFORMAT);
              this.glDate = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'FMS_PROJECT') {
        this.fmsProject.text = null;
        this.fmsProject.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (vsetCode === 'UOM') {
        this.rateUom.text = null;
        this.rateUom.value = null;
      } else if (vsetCode === 'RECOVERY_BASIS') {
        this.recoveryBasis.text = null;
        this.recoveryBasis.value = null;
      } else if (vsetCode === 'RECOVERY_MODE_VSET') {
        this.recoveryMode.text = null;
        this.recoveryMode.value = null;
      } else if (vsetCode === 'TDS_TAX_CATEGORY_ACTUAL') {
        this.taxCategoryActual.text = null;
        this.taxCategoryActual.value = null;
      } else if (vsetCode === 'TDS_SECTION_ACTUAL') {
        this.taxSection.text = null;
        this.taxSection.value = null;
      } else if (vsetCode === 'INV_BATCH_NAME') {
        this.invoiceBatchName = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
